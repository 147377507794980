.mat-mdc-list.mat-mdc-list-base {
  padding: 10px 20px 10px 10px;
  border: 2px solid var(--border);
  border-radius: var(--borderRadius);
}

.mat-mdc-list-item {
  border-radius: var(--borderRadius) !important;
  border: 2px solid var(--border);
  margin: 20px 0;

  .mat-mdc-list-item-unscoped-content {
    color: var(--text) !important;
  }

  &:first-child {
    margin: 0 0 20px 0;
  }

  &:last-child {
    margin: 20px 0 0 0;
  }

  &:hover:not(.mdc-list-item--disabled),
  &.dtu-active {
    border-color: var(--blue);
  }

  &:hover {
    cursor: pointer !important;
  }
}
